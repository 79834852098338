// GETTERS
export const STORE_MODULE_AUTH_GETTER_IS_ADMIN = 'isAdmin';
export const STORE_MODULE_AUTH_GETTER_IS_USER = 'isUser';
export const STORE_MODULE_AUTH_GETTER_GET_ADMIN_ROLES = 'getAdminRoles';
export const STORE_MODULE_AUTH_GETTER_GET_USER_ROLES = 'getUserRoles';
export const STORE_MODULE_AUTH_GETTER_OIDC_ACCESS_TOKEN = 'oidcAccessToken';
export const STORE_MODULE_AUTH_GETTER_OIDC_IS_AUTHENTICATED =
  'oidcIsAuthenticated';
export const STORE_MODULE_AUTH_GETTER_OIDC_USER = 'oidcUser';

// MUTATIONS
export const STORE_MODULE_AUTH_MUTATION_SET_IS_ADMIN = 'setIsAdmin';
export const STORE_MODULE_AUTH_MUTATION_SET_IS_USER = 'setIsUser';

// ACTIONS
export const STORE_MODULE_AUTH_ACTION_CHECK_ADMIN_ACCESS = 'checkAdminAccess';
export const STORE_MODULE_AUTH_ACTION_CHECK_USER_ACCESS = 'checkUserAccess';
export const STORE_MODULE_AUTH_ACTION_OIDC_CHECK_ACCESS = 'oidcCheckAccess';
export const STORE_MODULE_AUTH_ACTION_OIDC_SIGN_IN_CALLBACK =
  'oidcSignInCallback';
