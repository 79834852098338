import { mapState } from 'vuex';

export default {
  data() {
    return {
      showTo: true,
      showCc: false,
      showBcc: false
    };
  },
  computed: {
    ...mapState('templates', ['currentTemplate'])
  }
};
