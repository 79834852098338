import {
  STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_SUCCESS,
  STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_ERROR,
  STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_INFO,
  STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_WARNING,
  STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_AUDIT
} from './modules/notifications/store-notifications-types';

import {
  STORE_MODULE_AUTH_ACTION_CHECK_ADMIN_ACCESS,
  STORE_MODULE_AUTH_ACTION_CHECK_USER_ACCESS,
  STORE_MODULE_AUTH_ACTION_OIDC_SIGN_IN_CALLBACK,
  STORE_MODULE_AUTH_GETTER_IS_ADMIN,
  STORE_MODULE_AUTH_GETTER_IS_USER,
  STORE_MODULE_AUTH_GETTER_OIDC_IS_AUTHENTICATED,
  STORE_MODULE_AUTH_GETTER_OIDC_ACCESS_TOKEN,
  STORE_MODULE_AUTH_GETTER_OIDC_USER
} from './modules/auth/store-auth-types';

import { STORE_MODULE_APPLICATION_GETTER_GET_CURRENT_APPLICATION } from './modules/applications/store-application-types';

export const STORE_ACTION_NOTIFY_SUCCESS =
  'notifications/' + STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_SUCCESS;
export const STORE_ACTION_NOTIFY_ERROR =
  'notifications/' + STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_ERROR;
export const STORE_ACTION_NOTIFY_INFO =
  'notifications/' + STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_INFO;
export const STORE_ACTION_NOTIFY_WARNING =
  'notifications/' + STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_WARNING;
export const STORE_ACTION_NOTIFY_AUDIT =
  'notifications/' + STORE_MODULE_NOTIFICATIONS_ACTION_NOTIFY_AUDIT;

export const STORE_MODULE_AUTH_NAMESPACE = 'auth';
export const STORE_ACTION_AUTH_CHECK_ADMIN_ACCESS =
  STORE_MODULE_AUTH_NAMESPACE +
  '/' +
  STORE_MODULE_AUTH_ACTION_CHECK_ADMIN_ACCESS;
export const STORE_ACTION_AUTH_CHECK_USER_ACCESS =
  STORE_MODULE_AUTH_NAMESPACE +
  '/' +
  STORE_MODULE_AUTH_ACTION_CHECK_USER_ACCESS;
export const STORE_ACTION_AUTH_OIDC_SIGN_IN_CALLBACK =
  STORE_MODULE_AUTH_NAMESPACE +
  '/' +
  STORE_MODULE_AUTH_ACTION_OIDC_SIGN_IN_CALLBACK;
export const STORE_GETTER_AUTH_IS_ADMIN =
  STORE_MODULE_AUTH_NAMESPACE + '/' + STORE_MODULE_AUTH_GETTER_IS_ADMIN;
export const STORE_GETTER_AUTH_IS_USER =
  STORE_MODULE_AUTH_NAMESPACE + '/' + STORE_MODULE_AUTH_GETTER_IS_USER;
export const STORE_GETTER_AUTH_OIDC_IS_AUTHENTICATED =
  STORE_MODULE_AUTH_NAMESPACE +
  '/' +
  STORE_MODULE_AUTH_GETTER_OIDC_IS_AUTHENTICATED;
export const STORE_GETTER_AUTH_OIDC_ACCESS_TOKEN =
  STORE_MODULE_AUTH_NAMESPACE +
  '/' +
  STORE_MODULE_AUTH_GETTER_OIDC_ACCESS_TOKEN;
export const STORE_GETTER_AUTH_OIDC_USER =
  STORE_MODULE_AUTH_NAMESPACE + '/' + STORE_MODULE_AUTH_GETTER_OIDC_USER;

export const STORE_MODULE_TEMPLATES_NAMESPACE = 'templates';

export const STORE_MODULE_APPLICATIONS_NAMESPACE = 'applications';
export const STORE_GETTER_CURRENT_APPLICATION =
  STORE_MODULE_APPLICATIONS_NAMESPACE +
  '/' +
  STORE_MODULE_APPLICATION_GETTER_GET_CURRENT_APPLICATION;
