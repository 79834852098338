import { STORE_GETTER_CURRENT_APPLICATION } from '@/store/store-types';
import createForm from '@/components/create-form/create-form.vue';

export default {
  components: { createForm },
  data() {
    return {
      application: {},
      applicationId: '',
      currentEnv: 'dev'
    };
  },
  mounted() {
    this.currentEnv = 'dev';
    this.applicationId = this.$route.params.id;
    this.application = this.loadApplicationGlobalData(this.applicationId);
  },
  watch: {
    $route: { handler: 'loadApplicationGlobalData', immediate: true }
  },
  methods: {
    loadApplicationGlobalData(ApplicationId) {
      return this.$store.getters[STORE_GETTER_CURRENT_APPLICATION](ApplicationId);
    }
  }
};
